import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Export from "../utils/Export";
import { useDispatch, useSelector } from 'react-redux';
import { video_submission_list } from "../../actions/videosubmission";
import { Link, useLocation } from "react-router-dom";
export default function VideoSubmission() {
	const dispatch = useDispatch()
	const location = useLocation()
	const [tableData, setTableData] = useState({
		tableTitle: "Video Submission", 
		onDeleteText: "Are you sure want to delete this customer?",
		disableDelete: true,
		customisedStatusUpdateMessage: true,
		onActiveText: "Are you sure you want to activate this customer?",
		onInactiveText: ["Are you sure you want to inactivate this customer? ", "The customer will no longer be logged into the system once you inactivate it."],
		tableHead: [
			{
				id: "name",
				label: "Name",
				// link: "/VideoSubmission/VideoSubmissionDetails/",
				// color: "var(--gradientColor2)"
			},
			{
				id: "email",
				label: "Customer Info",
				subText: "mobile_number"
			},
			{
				id: "store_name",
				label: "Store Name",
			},
			{
				id: "video_category",
				label: "Video Category",

			},
			{
				id: "video_language",
				label: "Language",
			
			},
			// {
			// 	id: "userLocation",
			// 	label: "Location",
			// },
			{
				id: "created_at",
				label: "Date",
			},
			{
				id: "status",
				label: "Status",
				isButtonDisplay: true,
			}
		],
		tableBody: [

		], filterColumn: [
			{
				id: "1",
				title: "Status",
				name: "status",
				options: ["Approved", "Rejected", "Pending"],
			},
			
		],
		isDateRangeFilter: "created_at"
	});
	const [form, setForm] = useState({});
	const [isEdit, setIsEdit] = useState(false);


	const videosubmission = useSelector((state) => state?.videosubmission?.videosubmission);
	useEffect(() => {
		dispatch(video_submission_list())
	}, [location])
	useMemo(() => {

		if (videosubmission?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = videosubmission?.data.map((ele) => ({ ...ele, name: <><Link to='/VideoSubmission/VideoSubmissionDetails/' state={{id:ele?.id}}><span style={ ele?.video_view == "true"?{ fontWeight: "300", color:"rgb(39, 37, 37)"  } :{ fontWeight: "700" , color:"black" }}>{ele?.firstname}</span>  <span style={ele?.video_view == "true"?{ fontWeight: "300", color:"rgb(39, 37, 37)"  } :{ fontWeight: "700" , color:"black" }}>{ele?.lastname}</span></Link></>,mobile: ele?.mobileNumber !== null ? ele?.mobileNumber : " - ", socialType1: ele?.socialType !== null ? "Gmail" : "Mobile"}))
			setTableData({ ...temp })
		}
	}, [videosubmission])

	// useMemo(() => {

	// 	if (subscriptions?.statuscode == 200) {
	// 		const temp = tableData
	// 		temp.filterColumn[0]["options"] = subscriptions?.data.map((ele) => (ele?.plan_name))
	// 		setTableData({ ...temp })
	// 	}
	// }, [subscriptions])


	return (
		<>


			<Export fileName={"Video Submission"} exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} />
			




			<ListTable
				tableData={tableData}
				key={"ListTable"}
				setForm={setForm}
				setTableData={setTableData}
				setIsEdit={setIsEdit}
			/>

		</>
	);
}

import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { city_create, city_delete, city_list, state_list } from "../../../actions/city";
import { locality_create, locality_delete, locality_list, locality_update } from "../../../actions/Masters/locality";
import { Country, State, City } from "country-state-city";
export default function Locality() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
    }
  }, [isEdit]);
  const path = location?.pathname?.split("/")[3];
  useEffect(() => {
    setView(path != "Locality" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditLocality");
  }, [location]);
  const [tableData, setTableData] = useState({
    tableTitle: "Locality",
    deleteRecord: locality_delete,
    // updateRecord: genre_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "locality_name",
        label: "Name",
      },
      {
        id: "area",
        label: "Area",
        subText:"pincode"
      },
      {
        id: "city",
        label: "City",
        // subText:"state"
      },
      {
        id: "state",
        label: "State",
      },
      {
        id: "edit",
        label: "Update",
      },
    ],
    tableBody: [],
  });
  const [formStructure ,setFormStructure] = useState([
    {
      id: "1",
      type: "inputBox",
      title: "Locality Title",
      name: "locality_name",
      maxLength: 30,
      // isCapitalise: true,
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Select State",
      name: "state",
      options: ["Portrait", "Landscape"],
      required: true,
    },    
    {
      id: "3",
      type: "select",
      title: "Select city",
      name: "city",
      options: ["Portrait", "Landscape"],
      required: true,
    },


    {
      id: "4",
      type: "inputBox",
      title: "area",
      name: "area",
      maxLength: 30,
      // isCapitalise: true,
      required: true,
    },
    {
      id: "5",
      type: "inputBox",
      title: `Area Pin Code`,
      name: "pincode",
      regex: /^[0-9\.]+$/,
      maxLength: "6",
      required: true
  },
    {
      id: "6",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]);
// console.log(formStructure,"newFormStru")
  const Locality = useSelector((state) => state?.masters?.locality_list);
  // const City = useSelector((state) => state?.masters?.city_list);

  useEffect(() => {
    dispatch(locality_list());
    // dispatch(city_list());

  }, []);
  useMemo(() => {
    const temp = formStructure;
    temp[1]["options"] = State.getStatesOfCountry("IN").map((ele) => ele?.name);
    setFormStructure([...temp]);
  }, [State]);
  useMemo(() => {
    const temp = formStructure;
    const StateName = State.getStatesOfCountry("IN")
      .map((ele) => ele?.name == form?.state && ele?.isoCode)
      .filter((e) => e)
      .toString();
    // console.log(StateName, "newSelect");
    // setForm({...form , state_code : StateName})
    temp[2]["options"] = City.getCitiesOfState("IN", StateName).map(
      (ele) => ele?.name
    );
    setFormStructure([...temp]);
  }, [form?.state, City]);

  useMemo(()=>{
    if(isEdit)
      {
        const temp = formStructure
        temp[1]["type"] = "inputBox"
        temp[1]["disabled"] = true
        temp[2]["type"] = "inputBox"
        temp[2]["disabled"] = true
        setFormStructure([...temp])
      } else{
        const temp = formStructure
        temp[1]["type"] = "select"
        temp[1]["disabled"] = false
        temp[2]["type"] = "select"
        temp[2]["disabled"] = false
        setFormStructure([...temp])
      }
  },[isEdit])
  useMemo(() => {
    if (Locality?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = Locality?.data
      setTableData({ ...temp });
    }
  }, [Locality]);

  // useMemo(()=>{
  //   if(State?.statuscode == 200){
  //     const temp = formStructure
  //     temp[0]["options"] = State?.data?.map((ele)=> ele?.state)
  //     setFormStructure([...temp])
  //   }
  // },[State])

  const formTitle = isEdit ? "Edit Locality" : "Create Locality";

  const message = useSelector((state) => state?.layout?.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    // const temp_form = form;
  //   temp_form["tempstate"] = form?.["state"];
  //   temp_form["state"] = State?.data
  //   .map(
  //     (option) =>
  //       form?.["state"]?.includes(option?.state) && option?.id
  //   )
  //   .filter((e) => e)[0];
  // setForm({
  //   ...temp_form,
  // });
    Object.keys(form).map((key) => data.append(key, form?.[key]));

    const temp = tableData;
    if (isEdit) {
      dispatch(locality_update(data));
    } else {
      dispatch(locality_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setTimeout(() => {
        dispatch(locality_list());
		navigate("/Masters/Locality/Locality", { state: { view: "view_all" } })
				setView("view_all");
      }, 1000);
    } else {
      setForm({ ...form });
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Masters/Locality/CreateLocality"}
        view_all={"/Masters/Locality/Locality"}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Masters/Locality/EditLocality"}
        />
      )}
    </>
  );
}

import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import CompetitionForm from '../components/CompetitionForm/CompetitionForm';

function CompetitionFormModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CompetitionForm' element={<Layout children={CompetitionForm()} />} />
            </Routes>
        </div>
    )
}
export default CompetitionFormModule
import { COMPETITION_FORM } from '../constants/actionTypes'
import * as api from '../api/index.js';




  export const competition_form_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.competition_form_list(formData);
      dispatch({ type: COMPETITION_FORM, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CategoryModule from './CategoryModule';
import SubCategoryModule from './SubCategoryModule';
import LanguageModule from './LanguageModule';
import TagModule from './TagModule';
import AreaModule from './Area';
import CityModule from './City';
import LocalityModule from './LocalityModule';
function MastersModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Category/*' element={<CategoryModule key="Category"/>}/>
< Route path='/SubCategory/*' element={<SubCategoryModule key="SubCategory"/>}/>
< Route path='/Language/*' element={<LanguageModule key="Language"/>}/>
< Route path='/Tag/*' element={<TagModule key="Tag"/>}/>
{/* <Route path='/Area/*' element ={<AreaModule key="Area"/>}/> */}
{/* <Route path='/City/*' element= {<CityModule key="City"/>}/> */}
<Route path='/Locality/*' element={<LocalityModule key="Locality"/>}/>
      </Routes>
    </div>
  );
}

export default MastersModule;

import React from "react";
import { useState, useEffect, useMemo } from "react";
import {
  all_complaint_list,
  complaint_status_update,
} from "../../actions/complaint";
import ListTable from "../utils/Table";
import EnlargedView from "../utils/EnlargedView";
import image from "./../../images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import Export from "./../utils/Export";
import { competition_form_list } from "../../actions/competitionForm";
export default function CompetitionForm() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const handleOpen = () => setOpen(true);
  const [tableData, setTableData] = useState({
    tableTitle: "Competition Form",
    disableDelete: true,
    // updateRecord: complaint_status_update,
    openModal: handleOpen,
    // onUpdateText: "Are you sure want to close the complaint?",
    tableHead: [
      {
        id: "user",
        label: "Name",
      },
    //   {
    //     id: "complaint_type",
    //     label: "Type",
    //   },

      {
        id: "email",
        label: "Contact Info",
        subText: "mobile_number",
      },

    //   {
    //     id: "device",
    //     label: "Device Details",
    //   },

      {
        id: "image_1",
        label: "Image",
        isImage: true,
        isModal: true,
        img_show : true
      },
      {
        id: "image_2",
        label: "Image",
        isImage: true,
        isModal: true,
        img_show : true

      },
      {
        id: "image_3",
        label: "Image",
        isImage: true,
        isModal: true,
        img_show : true

      },
      {
        id: "created_at",
        label: "Created At",
        // color:"green",
        // isSpecial: true,
        // subText: "closed_at1",
      },
    //   {
    //     id: "description",
    //     label: "Description",
    //     isModal: true,
    //     default: (
    //       <>
    //         <button
    //           style={{
    //             padding: "5px 15px",
    //             color: "rgb(238, 127, 37)",
    //             background: "transparent",
    //             border: "1px solid rgb(238, 127, 37)",
    //             borderRadius: "5px",
    //           }}
    //         >
    //           VIEW
    //         </button>
    //       </>
    //     ),
    //   },

    //   {
    //     id: "status",
    //     label: "Status",
    //     // keywords: ["Open", "Close"],
    //     // nonEditableState: "Close",
    //   },
    ],
    tableBody: [],
    filterColumn: [
    //   {
    //     id: "1",
    //     title: "Complaint Type",
    //     name: "complaint_type",
    //     options: [
    //       "Player Not working",
    //       "Subscription not working",
    //       "Content is not appropriate",
    //       "Profile Related issue",
    //       "Application Related",
    //       "Payment related issue",
    //       " Payment done but subscription not working",
    //       "Other",
    //     ],
    //   },
    //   {
    //     id: "2",
    //     title: "Status",
    //     name: "status",
    //     options: ["Open", "Close"],
    //   },
    ],
    // isDateRangeFilter: "created_at",
  });

  const competition_form = useSelector((state) => state?.competition_form?.competition_form);
  useEffect(() => {
    dispatch(competition_form_list());
  }, []);
//   console.log(competition_form, "kfkfkf");
  useMemo(() => {
    if (competition_form?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = competition_form?.data.map((ele) => ({
        ...ele,
        user: ele?.firstname + " " + ele?.lastname,
        device: ele?.user?.deviceId,
        deviceType: ele?.user?.deviceType,
      }));
      setTableData({ ...temp });
    }
  }, [competition_form]);

  return (
    <>
      {/* <Export fileName={"Complaints"} isClubed={false} exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} /> */}
      <EnlargedView open={open} setOpen={setOpen} content={content} />

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setTableData={setTableData}
        setContent={setContent}
      />
    </>
  );
}

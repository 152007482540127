import * as actionType from "../constants/actionTypes";

const competitionFormReducer = (state = { competitionFormData: null }, action) => {
	switch (action.type) {
		
		case actionType.COMPETITION_FORM:
			return {...state,competition_form: action.payload};
		default:
			return state;
	}
};

export default competitionFormReducer;
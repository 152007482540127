import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import poster from "./../../images/poster.jpg";
import "./VideoSubmissionDetails.css";
import VideoProfile from "./VideoProfile";
import { movie_details } from "../../actions/Movie/video";
import { episode_details } from "./../../actions/WebSeries/episode";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE } from "./../../api/index";
// import WatchedCustomers from './WatchedCustomers'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  video_submission_details,
  video_submission_status_update,
} from "../../actions/videosubmission";

const VideoSubmissionDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const formData = new FormData();
  formData.append("id", location.state?.id);
  useMemo(() => {
    dispatch(video_submission_details(formData));
    // 	formData.append("series_type" ,"Web Series")
    // else formData.append("series_type" ,"Super Short Series")
  }, [location.state?.id]);
  const data = useSelector(
    (state) => state?.videosubmission?.videosubmissionDetails?.data
  );
  const [disBtn, setDisBtn] = useState(false);
  const [rejectMsg, setRejectMsg] = useState("");

  const [showRejectBtn, setShowRejectBtn] = useState(false);

  useMemo(() => {
    if (data?.status == "Approved") {
      setDisBtn(true);
      // window.location.reload(true)
    } else {
      setDisBtn(false);
    }
  }, [data?.status]);
  const handleApprove = () => {
    const data = new FormData();
    data.append("id", location.state?.id);
    data.append("status", "Approved");
    dispatch(video_submission_status_update(data));
    window.location.reload();
    setShowRejectBtn(false);
  };
  const handleReject = () => {
    setShowRejectBtn(true);
  };
  const handleRegMsg = () => {
    const data = new FormData();
    data.append("id", location.state?.id);
    data.append("status", "Rejected");
    data.append("reject_reason", rejectMsg);
    dispatch(video_submission_status_update(data));
    setRejectMsg("");
    window.location.reload();
  };
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
      >
        <Grid item spacing={4} xs={12} sm={12} md={6} lg={7} xl={7}>
          <Card
            sx={{
              boxShadow: "none",
              borderRadius: "10px",
              p: "15px 25px",
              mb: "15px",
            }}
          >
            <Box mt={2}>
              <Tabs className="product-details-tabs">
                <TabList>
                  <Tab>Video</Tab>
                  <Tab>Customer</Tab>
                </TabList>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "black",
                      }}
                    >
                      <span style={{ color: "#fff" }}>{"Video"} Detail </span>
                    </Typography>

                    <Box>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Video Category:
                        </span>
                        {data?.video_category}
                      </Typography>
                      {
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Video Subcategory:
                          </span>
                          {data?.video_subcategory?.map(
                            (ele, index) =>
                              (index ? ", " : "") + ele?.subcategory_name
                          )}
                        </Typography>
                      }
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Video Language:
                        </span>
                        {data?.video_language}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Tags:
                        </span>
                        {data?.video_tag?.map(
                          (ele, index) => (index ? ", " : "") + ele?.tag_title
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Store Location:
                        </span>
                        {data?.target_location}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Catalogue:
                        </span>
                        {data?.catalogue}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          video Link:
                        </span>
                        {data?.video_link}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Video Description:
                        </span>
                        {data?.description}
                      </Typography>
                    </Box>
                  </Card>
                </TabPanel>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      mb: "10px",
                      backgroundColor: "#F7FAFF",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "black",
                      }}
                    >
                      <span style={{ color: "#fff" }}>Customer Detail </span>
                    </Typography>

                    <Box>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Name:
                        </span>
                        {data?.firstname + " " + data?.lastname}
                      </Typography>
                      {
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Email:
                          </span>
                          {data?.email}
                        </Typography>
                      }
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Mobile Number:
                        </span>
                        {data?.mobile_number}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Created At:
                        </span>
                        {data?.created_at}
                      </Typography>
                     
                    </Box>
                  </Card>
                </TabPanel>
              </Tabs>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5} key={"sdcsdc"}>
          <VideoProfile data={data} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
          <Button
            disabled={disBtn}
            style={{
              background:
                "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
            }}
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              mt: "10px",
              p: "10px 30px",
              fontSize: "14px",
              color: "#fff !important",
            }}
            variant="contained"
            className="mr-10px"
            onClick={handleApprove}
          >
            {data?.status === "Approved" ? "Approved" : "Approve"}
          </Button>
          <Button
            disabled={disBtn}
            style={{
              background:
                "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",

              display: data?.status === "Approved" ? "none" : "",
            }}
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              mt: "10px",
              p: "10px 30px",
              fontSize: "14px",
              color: "#fff !important",
            }}
            variant="contained"
            className="mr-10px"
            onClick={handleReject}
          >
            {data?.status === "Rejected" ? "Rejected" : "Reject"}
            {/* Reject  */}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          key={"-grid"}
          lg={12}
          display={showRejectBtn ? "block" : "none"}
          sx={{ mt: "1rem" }}
          textAlign="center"
        >
          <TextField
            autoComplete="Reject Reason"
            name="RejectMsg"
            fullWidth
            id="1"
            type="text"
            required="true"
            value={rejectMsg}
            label="Reject Reason"
            // helperText={"yes"}

            // helperText={(((isMulti && errorMessage) || !allowSubmit)
            // 	&& (form?.[value.name] == undefined ||
            // 		form?.[value.name]?.length <= 0) &&
            // 		value?.required) && "Please fill this field" ||
            // 		(value?.endsWith &&
            // 			!(
            // 			  form?.[value.name]?.endsWith(value?.endsWith) ||
            // 			  form?.[value.name]?.endsWith(value?.endsWith1)
            // 			)) &&
            // 		  (value?.errorText || "Incorrect format")}
            // helperText={((isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field") || (value?.endsWith && (!form?.[value.name]?.endsWith(value?.endsWith) && (value?.errorText || "Incorrect format")))}
            onChange={(event) => {
              setRejectMsg(event.target.value);
            }}
            InputProps={{
              inputProps: {
                maxLength: 250,
              },
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5px",
            }}
          >
            <p style={{ color: "#fff" }}>
              {rejectMsg.length}/{250}
              {rejectMsg.length >= 250 && (
                <span style={{ color: "red" }}>Maximum limit reached</span>
              )}
            </p>
          </div>
          <Button
            disabled={disBtn}
            style={{
              background:
                "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
            }}
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              mt: "10px",
              p: "10px 30px",
              fontSize: "14px",
              color: "#fff !important",
            }}
            variant="contained"
            className="mr-10px"
            onClick={handleRegMsg}
          >
            {/* {producerformdata?.status !== "Pending" ? "Approved" : "Approve"} */}
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default VideoSubmissionDetails;
